import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
	history: createWebHistory(""),
	routes: [
		{
      path: "/",
      name: "mainLayout",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: '/',
          name: 'home',
          component: ()=> import('@/components/main/Main.vue'),
        },
        {
          path: '/main',
          name: 'main',
          component: ()=> import('@/components/main/Main.vue'),
        },
        {
          path: '/user/form',
          name: 'FormUser',
          component: ()=> import('@/components/user/FormUser.vue'),
        },
        {
          path: '/test/grid',
          name: 'GridTest',
          component: ()=> import('@/components/test/GridTest.vue'),
        }

      ]
    },
		{
      path: "/",
			name: "subLayout",
			component: () => import("@/layouts/SubLayout.vue"),
      children: [

        {
          path: '/common/intro',
          name: 'Intro',
          component: () => import("@/components/common/Intro.vue"),
        },


        {
          path: '/notice/write',
          name: 'NoticeWrite',
          component: () => import("@/components/notice/Write.vue"),
        },

        {
          path: '/login',
          name: 'login',
          component: () => import("@/components/login/Login.vue"),
        },
        {
          path: '/user/join',
          name: 'join',
          component: () => import("@/components/user/Join.vue"),
        },
        {
          path: '/certificate/mobile',
          name: 'CertificateMobile',
          component: () => import("@/components/certificate/Mobile.vue"),
        },

        {
          path: '/my',
          name: 'my',
          component: () => import("@/components/user/My.vue"),
        },
        {
          path: '/user/find/id',
          name: 'findid',
          component: () => import("@/components/user/FindId.vue"),
        },
        {
          path: '/user/find/pw',
          name: 'findpw',
          component: () => import("@/components/user/FindPw.vue"),
        },
        {
          path: '/user/leave',
          name: 'UserLeave',
          component: () => import("@/components/user/Leave.vue"),
        },

        {
          path: '/user/join/register',
          name: 'register',
          component: () => import("@/components/user/Register.vue"),
        },
        {
          path: '/user/join/complete',
          name: 'JoinComplete',
          component: () => import("@/components/user/JoinComplete.vue"),
        },

        {
          path: '/user/config',
          name: 'config',
          component: () => import("@/components/user/Config.vue"),
        },
        {
          path: '/user/nemonickey',
          name: 'nemonicKey',
          component: () => import("@/components/user/NemonicKey.vue"),
        },
        {
          path: '/user/wallet',
          name: 'wallet',
          component: () => import("@/components/user/Wallet.vue"),
        },
        {
          path: '/user/search',
          name: 'UserSearch',
          component: () => import("@/components/user/Search.vue"),
        },

        {
          path: '/nft/list',
          name: 'NftSearch',
          component: () => import("@/components/nft/List.vue"),
        },
        {
          path: '/nft/list/:category?/:group?',
          name: 'NftList',
          component: () => import("@/components/nft/List.vue"),
        },
        {
          path: '/nft/detail/:nftid?',
          name: 'NftDetail',
          component: () => import("@/components/nft/Detail.vue"),
        },
        {
          path: '/nft/update/:nftid?',
          name: 'NftUpdate',
          component: () => import("@/components/nft/Update.vue"),
        },

        {
          path: '/nft/view/:nftid?',
          name: 'NftView',
          component: () => import("@/components/nft/Detail.vue"),
        },

        {
          path: '/nft/cart',
          name: 'cart',
          component: () => import("@/components/nft/Cart.vue"),
        },

        {
          path: '/nft/buy/:nftid?',
          name: 'NftBuy',
          component: () => import("@/components/nft/Buy.vue"),
        },
        {
          path: '/nft/user/author/:author?',
          name: 'ListByAuthor',
          component: () => import("@/components/nft/ListByAuthor.vue"),
        },
        {
          path: '/nft/user/owner/:owner?',
          name: 'ListByOwner',
          component: () => import("@/components/nft/ListByOwner.vue"),
        },

        {
          path: '/nft/search/:category?/:group?',
          name: 'search',
          component: () => import("@/components/nft/Search.vue"),
        },

        {
          path: '/nft/author',
          name: 'author',
          component: () => import("@/components/nft/Author.vue"),
        },

        {
          path: '/nft/owner',
          name: 'owner',
          component: () => import("@/components/nft/Owner.vue"),
        },
        {
          path: '/nft/favorite',
          name: 'favorite',
          component: () => import("@/components/nft/Favorite.vue"),
        },

        {
          path: '/nft/mint/:category?/:group?',
          name: 'mint',
          component: () => import("@/components/nft/Mint.vue"),
        },
        {
          path: '/nft/mint/multi',
          name: 'mintMulti',
          component: () => import("@/components/nft/MintMulti.vue"),
        },
        {
          path: '/test/pdf',
          name: 'PdfTest',
          component: () => import("@/components/nft/Pdf.vue"),
        },

        {
          path: '/nft/mint/multi/preview',
          name: 'mintMultiPreview',
          component: () => import("@/components/nft/MintMultiPreview.vue"),
        },
        {
          path: '/nft/mint/ai',
          name: 'mintAi',
          component: () => import("@/components/nft/MintAi.vue"),
        },

        {
          path: '/pay/charge',
          name: 'charge',
          component: () => import("@/components/pay/Charge.vue"),
        },
        {
          path: '/pay/withdraw',
          name: 'withdraw',
          component: () => import("@/components/pay/Withdraw.vue"),
        },
        {
          path: '/pay/payment/:nftid?',
          name: 'payment',
          component: () => import("@/components/pay/Payment.vue"),
        },
        {
          path: '/pay/transaction',
          name: 'transaction',
          component: () => import("@/components/pay/Transaction.vue"),
        },

        {
          path: '/message/receive',
          name: 'MessageReceive',
          component: () => import("@/components/message/Receive.vue"),
        },

        /*
        {
          path: '/user/login',
          name: 'userlogin',
          component: ()=> import('@/components/user/Login.vue'),
        },
        */

        {
          path: '/login/naver',
          name: 'naver',
          component: () => import("@/components/login/Naver.vue"),
        },
        {
          path: '/login/google',
          name: 'google',
          component: () => import("@/components/login/Google.vue"),
        },

        {
          path: '/login/kakao',
          name: 'kakao',
          component: () => import("@/components/login/Kakao.vue"),
        },
        {
          path: '/user/nemonic',
          name: 'nemonic',
          component: ()=> import('@/components/user/Nemonic.vue'),
        },
        {
          path: '/user/ntest',
          name: 'testnemonic',
          component: ()=> import('@/components/user/Ntest.vue'),
        },

        {
          path: '/user/test',
          name: 'UserTest',
          component: ()=> import('@/components/user/Nemonic_old.vue'),
        },

        {
          path: '/board/:type?/write',
          name: 'BoardWrite',
          component: ()=> import('@/components/board/Write.vue'),
        },
        {
          path: '/board/:type?/list',
          name: 'BoardList',
          component: ()=> import('@/components/board/List.vue'),
        },
        {
          path: '/board/:type?/view/:id?',
          name: 'BoardView',
          component: () => import("@/components/board/View.vue"),
        },
        {
          path: '/board/:type?/update/:id?',
          name: 'BoardUpdate',
          component: () => import("@/components/board/Update.vue"),
        },

        {
          path: '/policy/terms',
          name: 'terms',
          component: ()=> import('@/components/policy/Terms.vue'),
        },
        {
          path: '/policy/copyright',
          name: 'copyright',
          component: ()=> import('@/components/policy/Copyright.vue'),
        },

        {
          path: '/policy/privacy',
          name: 'privacy',
          component: ()=> import('@/components/policy/Privacy.vue'),
        },
        {
          path: '/inquiry/partner',
          name: 'partner',
          component: ()=> import('@/components/inquiry/Partner.vue'),
        },
        {
          path: '/inquiry/direct',
          name: 'direct',
          component: ()=> import('@/components/inquiry/Direct.vue'),
        },

      ]
		},
		{
      path: "/",
			name: "emptyLayout",
			component: () => import("@/layouts/EmptyLayout.vue"),
      children: [

        {
          path: '/test',
          name: 'test',
          component: () => import("@/components/test/Test.vue"),
        },
        {
          path: '/error500',
          name: 'error500',
          component: ()=> import('@/components/common/Error500.vue'),
        },
        {
          path: '/certificate/mobile/callback',
          name: 'CertificateMobileCallback',
          component: () => import("@/components/certificate/MobileCallback.vue"),
        },

      ]
		}


  ],
});

/*
const router = createRouter({
	history: createWebHistory(""),
	routes: [
		{
      path: "/",
			name: "home",
			component: "@/App.vue",
		},
		{
      path: "/login",
			name: "login",
			component: () => import("@/components/views/UserLogin.vue"),
		}
  ],
});
*/
export default router;
